
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { useSelector, useDispatch } from "react-redux";
import { setMenuOpen, selectMenuIsOpen, setUseMenu, selectMenuIsInuse, setModule, selectModule } from "./stores/menuApi";
import Loader from "./Loader";

import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getModule } from './Utility';

import DrawerTop from './components/DrawerTop';

//logout
import { setUserInfo } from "./stores/auth";
import Logout from './components/Logout';

import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import BarChartIcon from '@mui/icons-material/BarChart';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { useGetModulesMutation, useLogoutMutation, useGetAvatarCodeMutation, useGetUserInfoMutation } from './stores/loginApi';
import InnerLoaderIndicator from './components/InnerLoaderIndicator';


const drawerWidth = 240;

const MenuIcons = new Map([
    ['Student', <PersonIcon />],
    ['Teacher', <SchoolIcon />],
    ['Classroom', <MeetingRoomIcon />],
    ['Schedule', <EventIcon />],
    ['ScheduleView', <EventIcon />],
    ['HourStatistic', <BarChartIcon />],
    ['StudentSchedule', <EventIcon />],
    ['TeacherSchedule', <EventIcon />],
    ['Subject', <MenuBookIcon />],
    ['User', <AccountBoxIcon />],
]);

const Main = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        minWidth: 200,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
}));


export default function RootPage(props) {

    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.up('sm'));

    const handleMenuClick = (module) => {
        loadModule(module);
    };

    const isOpen = useSelector(selectMenuIsOpen);
    const dispatch = useDispatch();

    const closeDrawer = () => {
        dispatch(setMenuOpen(false));
    };

    const inuse = useSelector(selectMenuIsInuse);

    const [menu, setMenu] = React.useState([]);
    const [isMini, setIsMini] = React.useState(false);
    const [getModules, { isLoading: getLoading }] = useGetModulesMutation();
    const [getUserInfo, { isLoading: getInfoLoading }] = useGetUserInfoMutation();
    React.useEffect(() => {
        dispatch(setMenuOpen(isSM));
        getModules().unwrap().then((response) => {
            if (response.status === 0) {
                if (response.data.modules.length > 0) {
                    setMenu(response.data.modules);
                    setIsMini(response.data.isMiniLogin);
                    let moduleName = getModule(window.location.href);
                    var module = null;
                    response.data.modules.map((m) => {
                        if (m.moduleController.toLowerCase() == moduleName.toLowerCase()) {
                            module = m;
                        }
                    });

                    if (module == null) {
                        module = response.data.modules[0];
                        //module = { moduleController: 'Dashboard'};
                        //module = { moduleController: 'AvatarEdit' };
                    }

                    handleMenuClick(module.moduleController);
                }
            }
        });

        window.addEventListener('hashchange', (event) => {
            if (window.location.hash.startsWith('#avatar')) {
                getUserInfo().unwrap().then((response) => {
                    if (response.status === 0) {
                        dispatch(setUserInfo(response.data));
                    }
                });
            }
        });
    }, []);

    const module = useSelector(selectModule);

    const loadModule = (m) => {
        if (m.length > 0) {
            if (!isSM) {
                closeDrawer();
            }
            dispatch(setModule(m));
        }
    };

    return (
        <React.Fragment>
            {inuse ? <Box sx={isSM ? { display: 'flex' } : {}}>
                <CssBaseline />
                <Drawer
                    sx={{ width: drawerWidth, flexShrink: 0, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth } }}
                    anchor={'left'}
                    open={isOpen}
                    variant={isSM ? "persistent" : "temporary"}
                    onClose={closeDrawer}>
                    <DrawerHeader>
                        <DrawerTop isMini={isMini}/>
                        <IconButton onClick={closeDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List sx={{ height: 'calc(100vh - 100px)', overflow: 'auto' }}>
                        {menu.map((menuItem) => {
                            return (
                                <ListItem key={menuItem.moduleID} disablePadding>
                                    {menuItem.moduleController == module && <div style={{ width: '5px', height: '48px', backgroundColor: '#039be5' }}></div>}
                                    <ListItemButton onClick={() => handleMenuClick(`${menuItem.moduleController}`)} selected={menuItem.moduleController == module}>
                                        <ListItemIcon sx={{ minWidth: '32px' }}>
                                            {MenuIcons.get(menuItem.moduleController)}
                                        </ListItemIcon>
                                        <ListItemText primary={`${menuItem.moduleName}`} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })}
                    </List>
                    {!isMini && < div style={{ position: 'absolute', bottom: 0, width: drawerWidth - 1, backgroundColor: '#ffffff' }}>
                        <Logout/>
                    </div>
                    }
                    <Divider orientation="vertical" flexItem />
                    {(getLoading || getInfoLoading ) && <InnerLoaderIndicator />}
                </Drawer>
                {isSM ? <Main open={isOpen} ><Loader module={module} /></Main> : <Loader module={module} />}
            </Box> : <Loader module={module} />}
            </React.Fragment>
    );
}
