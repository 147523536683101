import React from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import InnerLoaderIndicator from './InnerLoaderIndicator';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TransitionFade = React.forwardRef(function Transition(props, ref) {
    return <Fade in ref={ref} {...props} />;
});

export default function EditDlg(props) {
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            fullScreen={!isSM}
            fullWidth={true}
            TransitionComponent={isSM ? TransitionFade :Transition}>
            <AppBar sx={{ position: 'relative'}}>
                <Toolbar sx={{ overflow: 'hidden' }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1, textAlign: 'center' }} variant="h8" component="div">{props.title}</Typography>
                    {props.rightBtns}
                </Toolbar>
            </AppBar>
            <DialogContent dividers={true}>
                    {props.children}
            </DialogContent>
            {props.loading && <InnerLoaderIndicator />}
        </Dialog>
        );
}
