import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: './api/',
        prepareHeaders: (headers, { getState }) => {
            const token = null;
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        },
    }),
    endpoints: () => ({}),
    keepUnusedDataFor:1
})


/*
 * add reducer to configurestore 
 *  [emptySplitApi.reducerPath]:emptySplitApi.reducer,
 */