import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";
import Fade from '@mui/material/Fade';
import InnerLoaderIndicator from './InnerLoaderIndicator';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
            bounds="body"
        >
            <Paper {...props} />
        </Draggable>
    );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(0),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 1, pl: 2, backgroundColor:'#03a9f4', textAlign: 'center', color:'white',fontSize:'16px' }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    size="small"
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

/*
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};
*/

export default function Dlg(props) {
    React.useEffect(() => {
    }, [props.title]);

    return (
        <BootstrapDialog
            aria-labelledby="draggable-dialog-title"
            open={props.open}
            TransitionComponent={Transition}>
            <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={props.handleClose}>
                {props.title ? props.title : "提示"}
            </BootstrapDialogTitle>
            {props.children}
            {props.loading && <InnerLoaderIndicator/>}
        </BootstrapDialog>
    );
}
