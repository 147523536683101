import React from "react";
//edit
import EditDlg from '../components/EditDlg'
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
//form
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useModifyPwdMutation } from '../stores/loginApi';

import { enqueueSnackbar } from 'notistack';

export default function PwdEdit(props) {

    const defaultFormData = { oldPwd: '', newPwd: '', confirmPwd: '' };
    const [formData, setFormData] = React.useState(defaultFormData);

    const [valids, setValids] = React.useState({});

    const handleInputChange = (event) => {
        const target = event.target;
        setFormData({ ...formData, [target.name]: target.value });
    };


    const [modifyPwd, { isLoading }] = useModifyPwdMutation();

    const doSubmit = () => {

        var newValids = {};
        if (formData.oldPwd.length === 0) {
            newValids.oldPwd = "请输入原密码";
        }

        if (formData.newPwd.length === 0) {
            newValids.newPwd = "请输入新密码";
        }

        if (formData.newPwd.length > 0 && formData.newPwd.length < 6) {
            newValids.newPwd = "密码长度不能少于6位";
        }

        if (formData.confirmPwd.length === 0) {
            newValids.confirmPwd = "请确认新密码";
        }

        if (formData.newPwd.length>0 && formData.confirmPwd.length > 0 && formData.confirmPwd != formData.newPwd) {
            newValids.confirmPwd = "新密码不一致";
        }


        setValids(newValids);

        if (Object.keys(newValids).length === 0) {           
            modifyPwd(formData).unwrap().then((response) => {
                if (response.status === 1) {
                    setValids({ ...response.validMsgs });
                    return;
                } else {
                    if (response.data) {
                        enqueueSnackbar("修改成功", {
                            variant: 'success'
                        });
                        props.handleComplete();
                    }
                }
            });
        }
    };

    return (
        <EditDlg
            open={props.open}
            onClose={props.handleClose}
            title="修改密码"
            loading={isLoading}
            rightBtns={<Button color="inherit" disabled={isLoading} onClick={doSubmit}>确认</Button>}>

            <Grid container spacing={3} >
                <Grid item xs={12}>
                    <TextField
                        required
                        id="oldPwd"
                        name="oldPwd"
                        label="原密码"
                        type="password"
                        fullWidth
                        variant="outlined"
                        value={formData.oldPwd}
                        onChange={handleInputChange}
                        error={valids.hasOwnProperty("oldPwd")}
                        helperText={valids.hasOwnProperty("oldPwd") ? valids.oldPwd : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="newPwd"
                        name="newPwd"
                        label="新密码"
                        type="password"
                        fullWidth
                        variant="outlined"
                        value={formData.newPwd}
                        onChange={handleInputChange}
                        error={valids.hasOwnProperty("newPwd")}
                        helperText={valids.hasOwnProperty("newPwd") ? valids.newPwd : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="confirmPwd"
                        name="confirmPwd"
                        label="密码确认"
                        type="password"
                        fullWidth
                        variant="outlined"
                        value={formData.confirmPwd}
                        onChange={handleInputChange}
                        error={valids.hasOwnProperty("confirmPwd")}
                        helperText={valids.hasOwnProperty("confirmPwd") ? valids.confirmPwd : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                        {valids.hasOwnProperty("errorMsg") && < Alert severity="error">{valids.errorMsg}</Alert>}
                </Grid>
            </Grid>
        </EditDlg>
    );
}

