import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

//const NewModule = React.lazy(() => import('./pages/' + props.module));
/*
 ['Main3', React.lazy(() => new Promise((resolve, reject) =>
        setTimeout(() => resolve(import("./pages/Main3")), 5000)
    ))],


const Modules = new Map([
    ['Dashboard', React.lazy(() => import('./pages/Dashboard'))],
    ['Student', React.lazy(() => import('./pages/Student'))],
    ['Teacher', React.lazy(() => import('./pages/Teacher'))],
    ['Classroom', React.lazy(() => import('./pages/Classroom'))],
    ['Schedule', React.lazy(() => import('./pages/Schedule'))],
    ['ScheduleView', React.lazy(() => import('./pages/ScheduleView'))],
    ['HourStatistic', React.lazy(() => import('./pages/HourStatistic'))],
    ['StudentSchedule', React.lazy(() => import('./pages/StudentSchedule'))],
    ['TeacherSchedule', React.lazy(() => import('./pages/TeacherSchedule'))],
    ['Subject', React.lazy(() => import('./pages/Subject'))],
    ['User', React.lazy(() => import('./pages/User'))],
    ['AvatarEdit', React.lazy(() => import('./pages/AvatarEdit'))],
]);
 */
const Loading = (<div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}><CircularProgress /></div>);

export default function Loader(props) {

    if (props.module !== '') {
        const NewModule = React.lazy(() => import(`./pages/${props.module}`)); //Modules.get(props.module);
        return (
            <React.Suspense fallback={Loading}>
                <NewModule />
            </React.Suspense>
        );
    } else {
        return <React.Fragment></React.Fragment>;
    }
}
