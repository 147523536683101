import { configureStore, StoreEnhancer, isRejectedWithValue, isFulfilled } from "@reduxjs/toolkit";
import { emptySplitApi } from './emptySplitApi'
import authReducer, { setCredentials } from "./auth";
import menuReducer from "./menuApi"
import { combineReducers } from "redux";
import { enqueueSnackbar } from 'notistack';

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        enqueueSnackbar('服务异常:'+action.payload.error, {
            variant: 'error'
        });
    }

    if (isFulfilled(action)) {
        if (action.payload.status === -1) {
            store.dispatch(setCredentials(false));
        }
    }

    return next(action)
}

export const store = configureStore({
    reducer: {
        auth: authReducer,
        menu: menuReducer,
        [emptySplitApi.reducerPath]: emptySplitApi.reducer
    },
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers(),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(emptySplitApi.middleware).concat(rtkQueryErrorLogger)
});


/*
https://github.com/reduxjs/redux-toolkit/issues/1326
https://github.com/reduxjs/redux/discussions/4114
import { configureStore, enableDynamicReducers } from '@reduxjs/toolkit';
import cheeseReducer from './cheeseSlice';

const store = configureStore({
  reducer: {
    cheese: cheeseReducer,
  },
  enhancers: [enableDynamicReducers],
});

import('./supremeSlice').then(supremeReducer => {
  store.addReducer(supremeReducer);
  store.removeReducer(cheeseReducer);
});
*/

// export const RootState = store.getState
// https://redux.js.org/usage/code-splitting#using-a-reducer-manager

/*
const staticReducers = {
  counter: counterReducer,
  auth: authReducer,
};

function createReducer(asyncReducers) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
}

function initStore() {
  const store = configureStore({reducer:staticReducers});

  store.asyncReducers = {};

  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers));
  };

  // Return the modified store
  return store;
}


export const store =initStore();
*/