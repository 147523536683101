import { emptySplitApi } from './emptySplitApi'

const url = 'login';

const loginApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getState: build.mutation({
            query: () => ({
                url: url,
                method: "GET"
            })
        }),
        checkCode: build.mutation({
            query: (code) => ({
                url: url + "/" + code,
                method: "GET"
            })
        }),
        login: build.mutation({
            query: (request) => ({
                url: url,
                method: "POST",
                body: request,
            })
        }),
        getModules: build.mutation({
            query: () => ({
                url: url + "/getModules",
                method: "GET"
            })
        }),
        logout: build.mutation({
            query: () => ({
                url: url + "/logout",
                method: "GET"
            })
        }),
        modifyPwd: build.mutation({
            query: (request) => ({
                url: url+"/modifyPwd",
                method: "POST",
                body: request,
            })
        }),
        getIcal: build.mutation({
            query: () => ({
                url: url + "/getIcal",
                method: "GET"
            })
        }),
        getAvatarCode: build.mutation({
            query: () => ({
                url: url + "/avatarCode",
                method: "GET"
            })
        }),
        getUserInfo: build.mutation({
            query: () => ({
                url: url + "/userinfo",
                method: "GET"
            })
        }),
    }),
    overrideExisting: false,
})

export const {
    useLoginMutation,
    useCheckCodeMutation,
    useGetModulesMutation,
    useGetStateMutation,
    useLogoutMutation,
    useModifyPwdMutation,
    useGetIcalMutation,
    useGetAvatarCodeMutation,
    useGetUserInfoMutation
} = loginApi;

/*
    const [test,{isLoading}]=useTestMutation();

    test(values).unwrap().then((str:string)=>{
        
    });
 
 */