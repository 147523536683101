import React from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "./stores/auth";
import Login from "./login";
import RootPage from "./RootPage"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import useMediaQuery from '@mui/material/useMediaQuery';

const theme = createTheme(
    {
        palette: {
            primary: {
                main: "#039be5"
                //main:"#993333"
            },
            neutral: {
                main:"#ffffff"
            }
        },
    }
);

function App(props) {
    /*
    var hidden, visibilityChange
    if (typeof document.hidden !== 'undefined') { 
        hidden = 'hidden'
        visibilityChange = 'visibilitychange'
    } else if (typeof document.msHidden !== 'undefined') {
        hidden = 'msHidden'
        visibilityChange = 'msvisibilitychange'
    } else if (typeof document.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden'
        visibilityChange = 'webkitvisibilitychange'
    }

    const handleVisibilityChange = () => {
        if (!document[hidden]) {
            console.log('resume');
        }
    };

    React.useEffect(() => {
        if (typeof window.document.addEventListener === 'undefined' || typeof document[hidden] === 'undefined') {
            console.log('Not Support Visibility API.')
        } else {
            //window.document.addEventListener(visibilityChange, handleVisibilityChange, false)
        }
    },[]);
    */

    const isLogin = useSelector(selectCurrentUser).isLogin;
    const isSM = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={5} autoHideDuration={2000} anchorOrigin={isSM?{ vertical: 'bottom', horizontal: 'right' } : { vertical: 'top', horizontal: 'center' }} variant="info">
                {isLogin ? <RootPage /> : <Login />}
            </SnackbarProvider>
        </ThemeProvider>
    );
}
export default App;