import React, { useState } from "react";
import "./App.css";
import LoadingButton from '@mui/lab/LoadingButton';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from "react-redux";
import { setCredentials, setUserInfo } from "./stores/auth";
import { useLoginMutation, useGetStateMutation, useCheckCodeMutation } from './stores/loginApi';
import { validResponse, getParams } from './Utility'
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from 'js-cookie'

function Login() {
    const dispatch = useDispatch();

    const [inited, setInited] = React.useState(false);
    const [miniLogin, setMiniLogin] = React.useState(false);

    const [getState] = useGetStateMutation();
    const [checkCode, { isLoading: checkLoading }] = useCheckCodeMutation();

    const defaultFormData = { account: '', password: '' };
    const [formData, setFormData] = React.useState(defaultFormData);

    React.useEffect(() => {
        getState().unwrap().then((response) => {
            if (response.status === 0) {
                dispatch(setCredentials(true));
                dispatch(setUserInfo(response.data));
            } else {
                const url = window.location.href;
                const params = getParams(url);
                if (params && params.hasOwnProperty('c')) {
                    window.history.replaceState(null, null, url.substring(0, url.indexOf('?')));
                    setMiniLogin(true);
                    checkCode(params.c).unwrap().then((response) => {
                        if (response.status === 0) {
                            dispatch(setCredentials(true));
                            dispatch(setUserInfo(response.data));
                        }
                    });
                }
            }
            setInited(true);

            //android auto login
            if (window.$$version) {
                var str = prompt("$#" + "{'type':'getUser'}" + "#$");
                var json = JSON.parse(str);

                if (json) {
                    setFormData(json);
                    login(json).unwrap().then((response) => {
                        if (validResponse(response)) {
                            if (response.status === 1) {
                                setValids({ ...response.validMsgs });
                                return;
                            }

                            dispatch(setCredentials(true));
                            dispatch(setUserInfo(response.data));
                        }
                    });
                }
            } else {
                var account = Cookies.get("account");
                if (account != undefined) {
                    setFormData({...formData, "account": account });
                }
            }
        });
    }, []);

    const [login, { isLoading }] = useLoginMutation();

    const [valids, setValids] = useState({});

    const handleInputChange = (event) => {
        const target = event.target;
        setFormData({ ...formData, [target.name]: target.value });
    };

    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }
        var newValids = {};
        if (formData.account.length === 0) {
            newValids.account = "请输入手机号码";
        }

        if (formData.password.length === 0) {
            newValids.password = "请输入密码";
        }

        setValids(newValids);

        if (Object.keys(newValids).length === 0) {
            login(formData).unwrap().then((response) => {
                if (validResponse(response)) {
                    if (response.status === 1) {
                        setValids({ ...response.validMsgs });
                        return;
                    }

                    //android save account
                    if (window.$$version) {
                        prompt("$#" + '{"type":"saveUser","value":' + JSON.stringify(formData) + '}' + "#$");
                    } else {
                        Cookies.set("account", formData.account, { expires: 7 });
                    }

                    dispatch(setCredentials(true));
                    dispatch(setUserInfo(response.data));
                }
            });
        }
    };

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <React.Fragment>
            {(checkLoading || !inited) ?
                < div style={{ position: 'absolute', top: 0, left: 0, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', backgroundColor: '#ffffff66', width: '100%', height: '100vh', zIndex: 30 }}>
                    <CircularProgress color="primary" />
                </div> :
                <header className="App-header">
                    {miniLogin ? <dv>认证失败，请重试</dv> :
                        < Container component="main" maxWidth="xs">
                            <CssBaseline />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <img src="./logo196.png" alt="" style={{width:'128px',height:'128px'}} />
                                <Box component="form" onSubmit={handleSubmit} noValidate  sx={{ mt: '16px', width: '350px' }}>
                                    <Box sx={{ height: '80px', display: 'flex', alignItems: 'top' }}>
                                        <TextField
                                            required
                                            fullWidth
                                            autoFocus
                                            id="account"
                                            label="手机号码"
                                            name="account"
                                            autoComplete="account"
                                            value={formData.account}
                                            onChange={handleInputChange}
                                            error={valids.hasOwnProperty("account")}
                                            helperText={valids.hasOwnProperty("account") ? valids.account : ""}
                                        />
                                    </Box>
                                    <Box sx={{ height: '80px', display: 'flex', alignItems: 'top', mt: 1}}>
                                        <FormControl fullWidth variant="outlined" required>
                                            <InputLabel htmlFor="password" error={valids.hasOwnProperty("password")}>密码</InputLabel>
                                            <OutlinedInput
                                                name="password"
                                                label="密码"
                                                type={showPassword ? 'text' : 'password'}
                                                id="password"
                                                autoComplete="current-password"
                                                value={formData.password}
                                                onChange={handleInputChange}
                                                error={valids.hasOwnProperty("password")}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            <FormHelperText error={valids.hasOwnProperty("password")}>{valids.hasOwnProperty("password") ? valids.password : ""}</FormHelperText>
                                        </FormControl>
                                    </Box>
                                    {false && < FormControlLabel
                                        sx={{ mt: 0 }}
                                        control={<Checkbox value="remember" color="primary"/>}
                                        label="记住我"
                                    />}
                                    <LoadingButton
                                        fullWidth
                                        variant="contained"
                                        type="submit"
                                        loading={isLoading}
                                        disabled={isLoading}
                                        sx={{
                                            textTransform: 'none',
                                            mt: 2
                                        }}>
                                        登录
                                </LoadingButton>
                                    <Box component="div" sx={{ height: '50px', mt: 3 }}>
                                        {valids.hasOwnProperty("errorMsg") && < Alert severity="error">{valids.errorMsg}</Alert>}
                                    </Box>
                                </Box>
                            </Box>
                        </Container>}
                </header>
            }
        </React.Fragment>
    );
}

export default Login;
