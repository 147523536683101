import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useSelector, useDispatch } from "react-redux";
import Avatar from '@mui/material/Avatar';
import { selectCurrentUser } from "../stores/auth";
import PwdEdit from '../pages/PwdEdit';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyIcon from '@mui/icons-material/Key';
import { useGetAvatarCodeMutation} from '../stores/loginApi';

//wx
import wx from 'weixin-js-sdk';

export default function (props) {
    const isMini = props.isMini;
    const userName = useSelector(selectCurrentUser).userName;
    const userAvatar = useSelector(selectCurrentUser).avatar;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [openPwd, setOpenPwd] = React.useState(false);
    const handleMdifyPwd = () => {
        setOpenPwd(true);
        setAnchorEl(null);
    };

    const handleCloseMdifyPwd = () => {
        setOpenPwd(false);
    };

    const [getAvatarCode, { isLoading: getCodeLoading }] = useGetAvatarCodeMutation();
    const handleUpdateAvatar = () => {
        getAvatarCode().unwrap().then((response) => {
            if (response.status === 0) {
                wx.miniProgram.navigateTo({ url: '../avatar/avatar?code=' + response.data });
                setAnchorEl(null);
            }
        });
    };

    return (
        <React.Fragment>
            < Menu
                autoFocus={false}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}>
                {isMini ?
                    <MenuItem onClick={handleUpdateAvatar}>
                        <ListItemIcon>
                            <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText>修改头像</ListItemText>
                    </MenuItem> :
                    <MenuItem onClick={handleMdifyPwd}>
                        <ListItemIcon>
                            <KeyIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>修改密码</ListItemText>
                    </MenuItem>
                }
            </Menu>
            <List sx={{ py: 0 }}>
                <ListItem sx={{ py: 0 }}>
                    <ListItemAvatar>
                        <Avatar src={`./images/${userAvatar}`} sx={{ cursor: 'pointer' }} onClick={handleClick} />
                    </ListItemAvatar>
                    <ListItemText primary={userName} />
                </ListItem>
            </List>
            {openPwd &&
                <PwdEdit
                    open={true}
                    handleClose={handleCloseMdifyPwd}
                    handleComplete={handleCloseMdifyPwd}
                />}
        </React.Fragment>
    );
}
