import { createSlice } from "@reduxjs/toolkit";


const slice = createSlice({
    name: 'menu',
    initialState: { open: false, inuse: true ,module:''},
    reducers: {
        setMenuOpen: (state, action) => {
            state.open = action.payload;
        },
        setUseMenu: (state, action) => {
            state.inuse = action.payload;
        },
        setModule: (state, action) => {
            state.module = action.payload;
        },
    }

})

export const { setMenuOpen, setUseMenu, setModule } = slice.actions

export default slice.reducer

export const selectMenuIsOpen = (state) => state.menu.open

export const selectMenuIsInuse = (state) => state.menu.inuse

export const selectModule = (state) => state.menu.module
