import * as React from 'react';
import { useDispatch } from "react-redux";

import LogoutIcon from '@mui/icons-material/Logout';
import Dlg from './Dlg';
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useLogoutMutation } from '../stores/loginApi';
import { setCredentials, setUserInfo } from "../stores/auth";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { setMenuOpen} from "../stores/menuApi";

export default function (props) {
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.up('sm'));

    const dispatch = useDispatch();
    const [logoutConfirm, setLogoutConfirm] = React.useState(false);
    const [logout] = useLogoutMutation();
    const doLogout = () => {
        logout().unwrap().then((response) => {
            if (response.status === 0) {

                //android logout 
                if (window.$$version) {
                    prompt("$#" + '{"type":"quit"}' + "#$");
                }

                dispatch(setCredentials(false));

                if (!isSM) {
                    dispatch(setMenuOpen(false));
                }
            }
        });
    };

    return (
    <React.Fragment>
        <List>
            <ListItem disablePadding>
                <ListItemButton onClick={() => (setLogoutConfirm(true))}>
                    <ListItemIcon sx={{ minWidth: '32px' }}>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="退出" />
                </ListItemButton>
            </ListItem>
        </List>
        {logoutConfirm &&
        <Dlg open={true}>
            <DialogContent dividers>
                <div style={{ width: "300px", textAlign: "center", height: '60px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    确认退出登录吗？
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => (setLogoutConfirm(false))}>
                    取消
                </Button>
                <Button variant="contained" autoFocus onClick={doLogout}>
                    确定
                </Button>
            </DialogActions>
        </Dlg>
        }
        </React.Fragment >
    );
}