export function validResponse(response) {
    return response.status!=-1;
}

export function getParams(url) {
    if (url.indexOf('?') > 0) {
        let query = url.substring(url.indexOf('?') + 1);
        let array = query.split('&');
        let params = {};
        array.map(item => {
            let index = item.indexOf('=');
            let key = item.substring(0, index);
            let value = item.substring(index + 1);
            params[key] = value; 
        });
        return params;
    } else {
        return null;
    }
}

export function getModule(url) {
    var module = url.substring(url.lastIndexOf('/') + 1);
    if (module.indexOf('?') > 0) {
        module = module.substring(0, module.indexOf('?'));
    }
    return module;
}

export function fixHeight() {
    if (window.$$version) {
        return 0;
    }

    if (/MicroMessenger/.test(navigator.userAgent)) {
        return 0;
    }

    if (/Android/.test(navigator.userAgent)) {
        return 40;
    }


    if (/iPhone/.test(navigator.platform)) {
        return 80;
    }

    if (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /Mac OS X/.test(navigator.userAgent)) {
        return 40;
    }

    return 0;
}

export function isMiniApp() {
    return /MicroMessenger/.test(navigator.userAgent);
}



export function copyContent(content) {
    var copyDom = document.createElement('div');
    copyDom.innerText = content;
    copyDom.style.position = 'absolute';
    copyDom.style.top = '0px';
    copyDom.style.right = '-9999px';
    document.body.appendChild(copyDom);
    
    var range = document.createRange();
    range.selectNode(copyDom);
    
    window.getSelection().removeAllRanges();
    
    window.getSelection().addRange(range);
    
    var successful = document.execCommand('copy');
    copyDom.parentNode.removeChild(copyDom);

    return successful;
}


/*
    import { enqueueSnackbar } from 'notistack';
    const showSnackbar=() => {
        enqueueSnackbar('That was easy!', {
            variant: 'error'
        });
    };
    */