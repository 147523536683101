import { createSlice } from "@reduxjs/toolkit";

const slice=createSlice({
    name: 'auth',
    initialState: { isLogin: false, userName: '', avatar: '' },
    reducers:{
        setCredentials: (state, action) => {
            state.isLogin=action.payload;
        },
        setUserInfo: (state, action) => {
            state.userName = action.payload.userName;
            state.avatar = action.payload.avatar;
        }
    }

})

export const { setCredentials, setUserInfo } = slice.actions

export default slice.reducer

export const selectCurrentUser = (state) => state.auth